import React from 'react'
import { Button } from 'components'
import './Style.css'
import CloseIcon from '@material-ui/icons/Close'
import { translate } from 'locales'
import useFetch from 'hooks/useFetch'
import useStorage from 'hooks/storageSync'

const TagSelector: React.FunctionComponent<any> = ({ scheduleUUID }: any) => {
  const [storage, setStorage]: any = useStorage(scheduleUUID, `tags`)

  const { data } = useFetch({
    url: 'doctors/v1/medical-record/tags/all',
    options: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })

  const filterSelectedPatient = data?.filter((item: any) =>
    storage?.tags?.tagsPatient?.includes(item.id)
  )
  const filterSelectedDoctor = data?.filter((item: any) =>
    storage?.tags?.tagsDoctor?.includes(item.id)
  )

  const addTag = (tag: string, type: 'tagsDoctor' | 'tagsPatient'): void => {
    const tags = storage?.tags?.[type] || []
    setStorage(type, [...tags, tag])
  }

  const removeTag = (tag: string, type: 'tagsDoctor' | 'tagsPatient'): void => {
    const removed = storage?.tags?.[type]?.filter((item: any) => item !== tag)
    setStorage(type, removed)
  }

  return (
    <div style={{ marginTop: 20 }}>
      <span>{`${translate('medicalRecord.patientComplaint')}`}</span>
      <div className="tag-container">
        {filterSelectedPatient?.map((tag: any) => (
          <Button
            key={tag.id}
            color="tag-active"
            type="button"
            className="tag-button"
            onClick={() => {
              removeTag(tag.id, 'tagsPatient')
            }}
          >
            <span>{tag.title}</span>
            <CloseIcon fontSize="small" />
          </Button>
        ))}
      </div>
      {data && (
        <div className="tag-container">
          {data?.map((tag: any) => (
            <Button
              color="tag"
              type="button"
              className="tag-button"
              key={tag.id}
              onClick={() => {
                addTag(tag.id, 'tagsPatient')
              }}
              disabled={storage?.tags?.tagsPatient?.includes(tag.id)}
            >
              {tag.title}
            </Button>
          ))}
        </div>
      )}

      <span>{`${translate('medicalRecord.perceivedDemand')}`}</span>
      <div className="tag-container">
        {filterSelectedDoctor?.map((tag: any) => (
          <Button
            key={tag.id}
            color="tag-active"
            type="button"
            className="tag-button"
            onClick={() => {
              removeTag(tag.id, 'tagsDoctor')
            }}
          >
            <span>{tag.title}</span>
            <CloseIcon fontSize="small" />
          </Button>
        ))}
      </div>
      {data && (
        <div className="tag-container">
          {data?.map((tag: any) => (
            <Button
              color="tag"
              type="button"
              className="tag-button"
              key={tag.id}
              onClick={() => {
                addTag(tag.id, 'tagsDoctor')
              }}
              disabled={storage?.tags?.tagsDoctor?.includes(tag.id)}
            >
              {tag.title}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

export default TagSelector
