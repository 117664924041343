// eslint-disable
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import api from 'configs/api'

import { Grid } from '@material-ui/core'
import { normalizeName } from 'utils'
import moment from 'moment'
import {
  formatDistanceToNow,
  addMinutes,
  subHours,
  differenceInMilliseconds,
  differenceInMinutes,
  parseISO,
  // differenceInHours,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { telephoneMask } from 'js-essentials-functions'
import useStorage from 'hooks/storageSync'

import { translate } from 'locales'

import { calcIMC } from 'utils/convert'

// Utils
import * as Global from 'global/styles.global'
import {
  Header,
  Card,
  Button,
  Loading,
  ModalShow,
  VideoCallSdk,
} from 'components'
import whatsappIcon from 'assets/icons/whatsapp.svg'
import videoIcon from 'assets/icons/video.svg'
import getAge from 'utils/getAge'
import useFetch, { useSWRConfig } from 'hooks/useFetch'
import StartService from 'components/Modal/StartService'
import PublicIcon from '@mui/icons-material/Public'
import ChatPatient from '../New/ChatPatient'
import { menuItems } from './utils'

import { IAccompaniment } from '../New/interfaces'

// Styles
import * as S from './styles'

// Components
import Resume from './Resume'
import Analysis from './Analysis'
import AnamneseNutri from './AnamneseNutri'
import History from './History'
import TermsPsico from './TermsPsico'
import NutritionalGuidance from './NutritionalGuidance'
import PsychologicalGuidance from './PsychologicalGuidance'

// Services
import {
  // findSchedule,
  initAndCancelSchedule,
  initVideoCall,
  sendCallPush,
} from '../New/services'

import { saveMedicalRecord, createAccompaniment } from './services'

import ImagesAndAttachments from './ImagesAndAttachments'

// Icons

const NewMedicalRecord = () => {
  const topContentRef = useRef<HTMLDivElement>(null)
  const params: any = useParams()
  const history = useHistory()
  const { mutate } = useSWRConfig()

  const [storage]: any = useStorage(params.scheduleUUID, '')

  const defaultAccompaniment: IAccompaniment = {
    state: 'sim',
    reason: '',
    day: '',
  }

  // State
  const [cancelDescription, setCancelDescription] = useState('')
  const [showCancelModal, setShowCancelModal] = useState('none')
  const [showFinishModal, setShowFinishModal] = useState('none')
  const [showInitModal, setShowInitModal] = useState('none')
  const [selectedMenu, setSelectedMenu] = useState(1)
  const [loading, setLoading] = useState(false)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [state, setState] = useState({
    showModal: false,
    message: '',
    url: '',
  })
  const [token, setToken] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null)
  const [timeVideo, setTimeVideo] = useState<string | null>(null)

  const [accompaniment, setAccompaniment] =
    useState<IAccompaniment>(defaultAccompaniment)

  const { data: appointment, isLoading } = useFetch({
    url: `/schedulings/ms/v1/${params?.scheduleUUID}/doctor`,
    options: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })

  const { data: userData } = useFetch({
    url: `accounts/ms/v1/user/${appointment?.user_id}/me`,
    condition: !!appointment?.user_id,
    options: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })
  const { data: userMedical } = useFetch({
    url: `accounts/v1/medical-record/user/${appointment?.user_id}`,
    condition: !!appointment?.user_id,
    options: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })
  const { data: planData } = useFetch({
    url: `payments/ms/v1/subscriptions/user/${appointment?.user_id}/details`,
    condition: !!appointment?.user_id,
    options: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })

  const isBr = appointment?.user_country === 'BR'
  const verifyIsVideo = () => {
    if (isBr) {
      if (
        ['boleto', 'pix', 'credit_card'].includes(
          planData?.subscription?.payment_method
        ) ||
        !planData?.subscription?.payment_method
      ) {
        return false
      }
    }
    return true
  }
  const verifySavedCallToken = async () => {
    const callToken = localStorage.getItem('callToken')
    const callAppointmentId = localStorage.getItem('callAppointmentId')

    if (callToken && callAppointmentId === params.scheduleUUID) {
      setToken(callToken)
    }
  }

  useEffect(() => {
    const handleSize = () => {
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleSize)

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  useEffect(() => {
    verifySavedCallToken()
  }, [])

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = subHours(new Date(), 3)
      const start = parseISO(appointment?.from_date_time)
      const difference = differenceInMilliseconds(start, now) - 3 * 60 * 1000

      if (difference <= 0) {
        setIsButtonDisabled(false)
        setTimeRemaining(0)
      } else {
        setTimeRemaining(difference)
        if (difference <= 0) {
          setIsButtonDisabled(false)
        } else {
          setIsButtonDisabled(true)
        }
      }
    }

    calculateTimeRemaining()

    const intervalId = setInterval(() => {
      calculateTimeRemaining()
    }, 1000)

    return () => clearInterval(intervalId)
  }, [appointment?.from_date_time])

  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000)
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60
    const remaining = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`

    if (milliseconds <= 0) {
      return {
        duration: 'menos de 3 minutos',
        remaining,
      }
    }

    const duration = formatDistanceToNow(
      addMinutes(new Date(), milliseconds / 60000),
      { includeSeconds: false, locale: ptBR }
    )

    return {
      duration,
      remaining,
    }
  }

  const hideModal = () => {
    // @ts-ignore
    setShowCancelModal('none')
  }

  const hideFinishModal = () => {
    setShowFinishModal('none')
  }
  const hideInitModal = () => {
    setShowInitModal('none')
  }
  const handleUpdateData = () => {
    mutate(`/schedulings/ms/v1/${params?.scheduleUUID}/doctor`)
  }
  const handleInitService = useCallback(() => {
    initAndCancelSchedule({
      scheduleUUID: appointment?.id,
      data: {
        status: 'started',
      },
      setLoading,
      history,
      action: handleUpdateData,
    })
  }, [history, appointment])
  const handleCancelSchedule = useCallback(() => {
    initAndCancelSchedule({
      scheduleUUID: appointment?.id,
      data: {
        status: 'canceled',
        cancel_description: cancelDescription,
      },
      setLoading,
      history,
      action: handleUpdateData,
    })
  }, [history, appointment, cancelDescription])

  const handleOpenWhatsApp = () => {
    const ddi = appointment?.user_cellphone?.includes('+')
      ? ''
      : isBr
        ? '55'
        : '1'

    window.open(`https://wa.me/${ddi}${appointment?.user_cellphone}`, '_blank')
  }

  const handleSaveMedicalRecord = async () => {
    setShowFinishModal('none')

    const data = {
      user_uuid: appointment?.user_id,
      schedule_uuid: appointment?.id,
      specialty: appointment?.specialty_id,
      ...storage?.analysis,
      ...storage?.tags,
      nutritional_anamnese: storage?.nutritional_anamnese,
    }

    if (
      accompaniment.state === 'sim' &&
      (appointment?.specialty_slug === 'psicologo' ||
        appointment?.specialty_slug === 'nutricionista')
    ) {
      const datas = {
        scheduling_id: appointment?.id,
        scheduling_date: appointment?.from_date_time,
        doctor_id: appointment?.doctor_id,
        doctor_name: appointment?.doctor_name,
        specialty_id: appointment?.specialty_id,
        specialty_name: appointment?.specialty_name,
        patient_id: appointment?.user_id,
        patient_name: appointment?.user_name,
        patient_cellphone: appointment?.user_cellphone,
        reason_accompaniment: accompaniment.reason,
        date_accompaniment: `${accompaniment.day} 00:00:00`,
      }
      const res = await createAccompaniment(datas)
      if (!res) {
        return
      }
    }
    saveMedicalRecord(
      appointment?.user_id,
      appointment?.id,
      data,
      history,
      setLoading
    )
  }
  const handleScrollToTop = () => {
    if (topContentRef.current) {
      setTimeout(() => {
        topContentRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }

  const doctorName = appointment?.doctor_name
  const doctorGender = appointment?.doctor_gender
  const doctorNameShow =
    doctorGender === 'male' ? `Dr. ${doctorName}` : `Dra. ${doctorName}`

  const verifyScrren: any = window.screen.width < 600

  const handleClickTime = async () => {
    try {
      await api.patch(`/schedulings/ms/v1/${appointment?.id}/doctor/video`)
      handleUpdateData()
    } catch (error) {
      console.log(error)
    }
  }

  const verifyTimeVideo = () => {
    const CancelInMinutes = appointment?.video_starts_at
      ? differenceInMinutes(new Date(), new Date(appointment?.video_starts_at))
      : 0

    if (
      !appointment?.video_starts_at &&
      differenceInMinutes(new Date(), new Date(appointment?.from_date_time)) >
        200
    ) {
      return false
    } else {
      return CancelInMinutes < 5
    }
  }

  useEffect(() => {
    const calculateTimeVideo = () => {
      if (appointment?.video_starts_at) {
        const diference = differenceInMilliseconds(
          new Date(),
          new Date(appointment?.video_starts_at)
        )

        const totalSeconds = Math.floor(diference / 1000)
        const hours = Math.floor(totalSeconds / 3600)
        const minutes = Math.floor((totalSeconds % 3600) / 60)
        const seconds = totalSeconds % 60

        if (seconds >= 0) {
          setTimeVideo(
            `${hours ? `${hours}h` : ''} ${minutes}m ${seconds < 10 ? '0' : ''}${seconds}s`
          )
        }
      } else {
        setTimeVideo('')
      }
    }

    calculateTimeVideo()

    const intervalId = setInterval(() => {
      calculateTimeVideo()
    }, 1000)

    return () => clearInterval(intervalId)
  }, [appointment?.video_starts_at])
  return (
    <S.Container>
      <div ref={topContentRef} />
      {(isLoading || loading) && (
        <Loading
          title={state.message || translate('medicalRecord.loadingData')}
        />
      )}
      <Header openNewTab={!!token} />
      <S.Content>
        <S.LeftContainer
          windowHeight={windowHeight}
          windowWidth={windowWidth}
          isCenter={!!token}
        >
          <S.GridContent container xl={12}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingLeft: 16, paddingRight: 16 }}
            >
              <S.MessageContainer>
                <S.MessageText>
                  <b>🚨 Atenção: </b> Durante a videochamada com o paciente, é
                  essencial permanecer na tela para garantir uma comunicação
                  contínua e sem interrupções. Sair da tela pode causar
                  problemas na transmissão de vídeo e áudio.
                </S.MessageText>
                <S.MessageText>
                  🚨 <b>Observação: </b> Enquanto a consulta por vídeo estiver
                  acontecendo não será possível finalizar ou cancelar o
                  atendimento!
                </S.MessageText>
                <S.MessageText>
                  <b>
                    🌟 Por favor, permaneça na tela enquanto a chamada estiver
                    ativa para oferecer a melhor experiência de atendimento ao
                    seu paciente. 🌟
                  </b>
                </S.MessageText>
              </S.MessageContainer>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{
                padding: '2px 6px',
                borderRightWidth: 1,
                borderRightColor: '#f0f2f8',
                borderRightStyle: 'solid',
                order: verifyScrren ? 2 : 1,
              }}
            >
              <Card styles={{ padding: 0 }}>
                <S.TopContent>
                  <div className="text-content">
                    <span>{translate('medicalRecord.attendance')}</span>
                    <p>
                      {moment
                        .utc(appointment?.from_date_time)
                        .format('DD/MM/YYYY HH:mm')}
                    </p>
                  </div>
                  <div className="text-content">
                    <span>{translate('medicalRecord.time')}</span>
                    <p style={{ color: 'red' }}>
                      {`${appointment?.quantity_slot === 2 ? 60 : 30} ${translate(
                        'medicalRecord.minutes'
                      )}`}
                    </p>
                  </div>
                  {/* {isBr && ( */}
                  <div className="text-content">
                    <span>WhatsApp</span>
                    <p>
                      {`${
                        isBr
                          ? telephoneMask(appointment?.user_cellphone || '')
                          : `${appointment?.user_cellphone?.includes('+') ? '' : '+1'} ${appointment?.user_cellphone}`
                      }`}
                    </p>
                  </div>
                  {/* )} */}
                  <div className="button-content">
                    {timeRemaining !== null && timeRemaining > 0 && (
                      <S.MessageContainer>
                        <S.MessageText>
                          🚨 Por favor, aguarde. O atendimento só poderá ser
                          iniciado 3 minutos antes do horário da consulta, sendo
                          assim você poderá iniciar a consulta em{' '}
                          <b>{formatTime(timeRemaining).duration}</b>.
                        </S.MessageText>
                        <S.MessageText>
                          <b>Tempo restante:</b>{' '}
                          {formatTime(timeRemaining).remaining}
                        </S.MessageText>
                      </S.MessageContainer>
                    )}
                    {timeVideo && (
                      <S.MessageText>
                        <b>Tempo de atendimento:</b> {timeVideo}
                      </S.MessageText>
                    )}
                    {appointment?.status === 'pending' && (
                      <Button
                        color="primary"
                        type="button"
                        size="normal"
                        styles={{ width: '100%', marginBottom: 10 }}
                        onClick={() => setShowInitModal('block')}
                        disabled={isButtonDisabled}
                      >
                        {translate('medicalRecord.initAttendance')}
                      </Button>
                    )}

                    {appointment?.status === 'started' && (
                      <>
                        <p
                          style={{
                            marginTop: 10,
                            fontSize: '0.875rem',
                            color: '#f48c42',
                            backgroundColor: '#fff5ec',
                            padding: '10px',
                            borderRadius: '10px',
                            textAlign: 'center',
                          }}
                        >
                          Os botões de finalizar e cancelar consulta estarão
                          disponíveis apenas após a conclusão do tempo de
                          espera, indicado pelo cronômetro.
                        </p>
                        <Button
                          color="primary"
                          type="button"
                          size="normal"
                          styles={{ width: '100%', marginBottom: 10 }}
                          onClick={() => setShowFinishModal('block')}
                          disabled={verifyTimeVideo() || !!token}
                          title={
                            token
                              ? 'O atendimento não pode ser finalizado enquanto a vídeo chamada estiver acontecendo!'
                              : 'Finalizar o atendimento'
                          }
                        >
                          {translate('medicalRecord.finishAttendance')}
                        </Button>

                        <Button
                          color="danger"
                          type="button"
                          size="normal"
                          styles={{ width: '100%' }}
                          disabled={verifyTimeVideo() || !!token}
                          title={
                            token
                              ? 'O atendimento não pode ser cancelado enquanto a vídeo chamada estiver acontecendo!'
                              : 'Cancelar o atendimento'
                          }
                          onClick={() => {
                            setState({ ...state, message: '' })
                            setShowCancelModal('block')
                          }}
                        >
                          {translate('medicalRecord.cancelAppointment')}
                        </Button>

                        <Button
                          color="success"
                          type="button"
                          size="normal"
                          icon={whatsappIcon}
                          iconStyles={{ width: 20, marginRight: 15 }}
                          styles={{ width: '100%', marginTop: 10 }}
                          onClick={() => {
                            handleOpenWhatsApp()
                            handleClickTime()
                          }}
                          disabled={appointment?.status === 'pending'}
                          title="Iniciar chat via WhatsApp"
                        >
                          {translate('medicalRecord.initChat')}
                        </Button>
                      </>
                    )}

                    {appointment?.status === 'started' && verifyIsVideo() && (
                      <Button
                        color="success"
                        type="button"
                        size="normal"
                        icon={videoIcon}
                        iconStyles={{ width: 18, marginRight: 15 }}
                        styles={{ width: '100%', marginTop: 10 }}
                        title={
                          token
                            ? 'A vídeo chamada está em andamento!'
                            : 'Iniciar video chamada interna com o paciente'
                        }
                        onClick={() => {
                          handleScrollToTop()
                          handleClickTime()
                          sendCallPush(appointment.id)
                          initVideoCall(
                            appointment?.id,
                            state,
                            setLoading,
                            setState,
                            setToken
                          )
                        }}
                        disabled={appointment?.status === 'pending' || !!token}
                      >
                        {translate('medicalRecord.initVideo')}
                      </Button>
                    )}
                  </div>
                </S.TopContent>
                <S.Menu>
                  <ul>
                    {menuItems.map((item: any, key: number) => {
                      if (appointment) {
                        return item.by.includes(appointment?.specialty_slug) ? (
                          <S.MenuList
                            key={item.id}
                            className={key === selectedMenu ? 'active' : ''}
                          >
                            <button
                              type="button"
                              onClick={() => setSelectedMenu(key)}
                            >
                              {item.title}
                            </button>
                          </S.MenuList>
                        ) : null
                      }
                    })}
                  </ul>
                </S.Menu>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              md={token ? 5 : 9}
              style={{ padding: '2px 6px', order: verifyScrren ? 3 : 2 }}
            >
              <Card>
                <S.PatientContent>
                  {appointment?.user_country === 'BR' ? (
                    <h3>{normalizeName(appointment?.user_name || '')}</h3>
                  ) : (
                    <div className="d-flex align-items-center">
                      <h3
                        style={{
                          borderRight: '2px solid #000',
                          paddingRight: '10px',
                        }}
                      >
                        {appointment?.user_name || ''}
                      </h3>
                      <div
                        className="d-flex align-items-center"
                        style={{ paddingLeft: '10px', gap: '5px' }}
                      >
                        <PublicIcon fontSize="large" />
                        <span>Paciente Global</span>
                      </div>
                    </div>
                  )}
                  <p>
                    <span>{translate('medicalRecord.age')}</span>
                    {`${getAge(userData?.birthdate || '')} anos`}
                    <br />
                    <span>{translate('medicalRecord.bmi')}</span>
                    {calcIMC(userMedical?.height, userMedical?.weight)
                      ? `${calcIMC(
                          userMedical?.height,
                          userMedical?.weight
                        )} kg/m²`
                      : translate('medicalRecord.unavailable')}
                    <br />
                    <span>{translate('medicalRecord.gender')}</span>
                    {userData?.gender === 'male' &&
                      translate('medicalRecord.male')}
                    {userData?.gender === 'female' &&
                      translate('medicalRecord.female')}
                    {userData?.gender === 'other' &&
                      translate('medicalRecord.other')}

                    {userData?.gender === null &&
                      translate('medicalRecord.uninformed')}
                    <br />
                    <span>{translate('medicalRecord.birthdate')}</span>
                    {moment(userData?.birthdate).format('DD/MM/YYYY')}
                    <br />
                    <span>{translate('medicalRecord.weight')}</span>
                    {`${userMedical?.weight || ''} kg`}
                    <br />
                    <span>{translate('medicalRecord.height')}</span>
                    {`${userMedical?.height || ''} cm`}
                    <br />

                    {/* {schedule?.patient?.company?.name && (
                      <>
                        <span>{translate('medicalRecord.company')}</span>
                        {schedule?.patient?.company?.name}
                        <br />
                      </>
                    )} */}
                  </p>
                  <Global.Line />
                </S.PatientContent>
                {selectedMenu === 0 && (
                  <ChatPatient
                    doctorName={doctorNameShow}
                    scheduleData={appointment}
                    scheduleUUID={appointment?.id}
                  />
                )}
                {selectedMenu === 1 && appointment && (
                  <Resume
                    doctorName={doctorNameShow}
                    scheduleId={appointment?.id}
                    medicalRecord={userMedical}
                    symptoms={appointment?.symptoms}
                  />
                )}
                {selectedMenu === 2 && (
                  <Analysis
                    doctorName={doctorNameShow}
                    scheduleUUID={appointment?.id}
                    scheduleData={appointment}
                  />
                )}
                {selectedMenu === 3 && (
                  <AnamneseNutri
                    doctorName={doctorNameShow}
                    scheduleUUID={appointment?.id}
                  />
                )}
                {selectedMenu === 4 && (
                  <History
                    doctorName={doctorNameShow}
                    scheduleUUID={appointment?.id}
                    schedule={appointment}
                    specialty={appointment.specialty_id}
                  />
                )}
                {selectedMenu === 5 && (
                  <>
                    <ImagesAndAttachments
                      doctorName={doctorNameShow}
                      scheduleUUID={appointment?.id}
                    />
                  </>
                )}
                {selectedMenu === 6 && (
                  <>
                    <TermsPsico
                      autorization={appointment.authorization}
                      age={getAge(userData?.birthdate || '')}
                    />
                  </>
                )}
                {selectedMenu === 7 && (
                  <>
                    <NutritionalGuidance
                      doctorName={doctorNameShow}
                      patientId={appointment?.user_id}
                      scheduleUUID={appointment?.id}
                      // nutritional={schedule?.nutritional?.attachment || false}
                      setLoading={setLoading}
                      // recipe={schedule?.nutritional?.recipe || false}
                    />
                  </>
                )}
                {selectedMenu === 8 && (
                  <>
                    <PsychologicalGuidance
                      doctorName={doctorNameShow}
                      patientId={appointment?.user_id}
                      scheduleUUID={appointment?.id}
                      // attachment={schedule?.attachment || false}
                      setLoading={setLoading}
                    />
                  </>
                )}
              </Card>
            </Grid>
          </S.GridContent>
        </S.LeftContainer>
        {token && (
          <S.RightContainer>
            <VideoCallSdk
              onCloseRoom={() => setToken('')}
              token={token}
              schedulingId={appointment?.id}
              windowHeight={windowHeight}
              windowWidth={windowWidth}
            />
          </S.RightContainer>
        )}
      </S.Content>
      <>
        <div className="overlay" style={{ display: showCancelModal }} />
        <div className="modal-wrapper" style={{ display: showCancelModal }}>
          <div className="info-container">
            <ModalShow
              closeModal={hideModal}
              setDescription={setCancelDescription}
              description={cancelDescription}
              handleConfirm={handleCancelSchedule}
            />
          </div>
        </div>
      </>
      <>
        <div className="overlay" style={{ display: showFinishModal }} />
        <div className="modal-wrapper" style={{ display: showFinishModal }}>
          <div className="info-container">
            {(appointment?.specialty_slug === 'psicologo' ||
              appointment?.specialty_slug === 'nutricionista') && (
              <ModalShow
                closeModal={hideFinishModal}
                cancelModal={false}
                handleConfirm={handleSaveMedicalRecord}
                setAccompaniment={setAccompaniment}
                accompaniment={accompaniment}
              />
            )}
          </div>
        </div>
        <div className="overlay" style={{ display: showInitModal }} />
        <div className="modal-wrapper" style={{ display: showInitModal }}>
          <div className="info-container">
            <StartService
              closeModal={hideInitModal}
              handleConfirm={handleInitService}
            />
          </div>
        </div>
      </>
    </S.Container>
  )
}

export default NewMedicalRecord
