import React from 'react'
import pdfIcon from 'assets/icons/pdf.svg'
import { translate } from 'locales'
// Styles
import { Title } from 'components'
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

import useFetch from 'hooks/useFetch'

const TimeLineResume: React.FunctionComponent<IComponentProps> = ({
  record,
}) => {
  const notInformad = translate('medicalRecord.uninformed')

  const { data } = useFetch({
    url: `files/exam/get/all/${record?.scheduling?.id}`,
    options: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })

  return (
    <S.SectionContent style={{ padding: 10 }}>
      <div className="subsection">
        <Title text="Anamnese" />
        <div className="subsection-item">
          <span>{translate('medicalRecord.reason')}</span>
          <p>{record?.scheduling?.symptoms || notInformad}</p>
        </div>
        <div className="subsection-item">
          <span>{translate('medicalRecord.compaint')}</span>
          <p>{record?.complaint || notInformad}</p>
        </div>
        <div className="subsection-item">
          <span>{translate('medicalRecord.historyCurrent')}</span>
          <p>{record?.history || notInformad}</p>
        </div>
        <div className="subsection-item">
          <span>{translate('medicalRecord.allergies')}</span>
          <p>{record?.allergies || notInformad}</p>
        </div>
        <div className="subsection-item">
          <span>{translate('medicalRecord.diabetes')}</span>
          <p>
            {record?.diabetes === 'sim'
              ? translate('general.yes')
              : translate('general.no')}
          </p>
        </div>
        <div className="subsection-item">
          <span>{translate('medicalRecord.pathologicalBackground')}</span>
          <p>{record?.pathological_background || notInformad}</p>
        </div>
        <div className="subsection-item">
          <span>{translate('medicalRecord.continuousRemedy')}</span>
          <p>{record?.continuous_remedy || notInformad}</p>
        </div>
      </div>

      <div className="subsection">
        <Title text={translate('medicalRecord.complementarExams')} />
        <div className="subsection-item">
          <p>{record?.exams || notInformad}</p>
        </div>
      </div>

      <div className="subsection">
        <Title text={translate('medicalRecord.diagnosticHypothesis')} />
        <div className="subsection-item">
          <span>{`${translate('medicalRecord.diagnostic')} :`}</span>
          <p>{record?.hypothesis || notInformad}</p>
        </div>
      </div>

      <div className="subsection">
        <Title text={translate('medicalRecord.clinicalImpression')} />
        <div className="subsection-item">
          <p>{record?.clinical_impression || notInformad}</p>
        </div>
      </div>

      <div className="subsection">
        <Title text={translate('medicalRecord.conduct')} />
        <div className="subsection-item">
          <p>{record?.conduct || notInformad}</p>
        </div>
      </div>
      <div className="subsection">
        <Title text={translate('medicalRecord.images')} />
        <div className="subsection-item">
          {data && data?.exams !== null ? (
            <section className="section-resume">
              <div className="content-resume">
                <S.AttachmentsList>
                  {data &&
                    data?.exams !== null &&
                    data.exams?.map((exam: any, index: number) => {
                      const typeImage = 'image'
                      const isImage = exam.mimetype.includes(typeImage)

                      return isImage ? (
                        <button
                          key={exam.file_key}
                          type="button"
                          className="images"
                          onClick={() => window.open(exam.file_url, '_blank')}
                        >
                          <img src={exam.file_url} alt="Imagem de um exame" />
                          <span>
                            {`${translate('medicalRecord.file')} ${index + 1}`}
                          </span>
                        </button>
                      ) : (
                        <button
                          key={exam.file_key}
                          type="button"
                          className="files"
                          onClick={() => window.open(exam.file_url, '_blank')}
                        >
                          <img
                            src={pdfIcon}
                            alt="Icone que representa um arquivo PDF"
                          />
                          <span>
                            {`${translate('medicalRecord.file')} ${index + 1}`}
                          </span>
                        </button>
                      )
                    })}
                </S.AttachmentsList>
              </div>
            </section>
          ) : (
            <p>Nenhum Anexo</p>
          )}
        </div>
      </div>
    </S.SectionContent>
  )
}

export default TimeLineResume
